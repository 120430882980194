import { appConfig } from 'src/app-config/appConfig'

export function makeAbsoluteUrl(url?: string | null) {
	if (!url) {
		return ''
	}
	if (url.startsWith('http') || url.startsWith('/')) {
		return url
	}
	return `${appConfig.WEB_ORIGIN}/${url}`
}

export function airlineLogo(carrier: { code: string }): string {
	return makeAbsoluteUrl(`static/airline-logos/${carrier.code}.png`)
}

export function hotelLoyaltyProgramLogo(hotelLoyalty: { loyalty_program_logo: string | null }): string {
	return makeAbsoluteUrl(`static/hotel-loyalty-program-logos/${hotelLoyalty.loyalty_program_logo}`)
}
