import { useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import sortBy from 'lodash/sortBy'
import React from 'react'
import onClickOut from 'react-onclickoutside'

import { boxShadowFloat, spacing } from 'src/_vars'
import CrossIcon from 'src/assets/cross.svg'
import { RelativeContainer } from 'src/atoms/GenericComponents/GenericComponents'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import TextField, { IProps as TextFieldProps } from 'src/atoms/TextField/TextField'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { eventStopper, getClosestElement, handleIOSInput, Key, triggerInputChangeEvent } from 'src/lib/utils'
import { MobileDialogWithArrow } from 'src/molecules/MobileDIalog'
import { linkBlue } from 'src/refactor/colors'
import { font, pixelFontSize } from 'src/refactor/fonts'
import styled, { flex } from 'src/styles'
import { DefaultProps, ResultSegment } from 'src/travelsuit'

import { OptionItem } from '../OptionItem'
import { LoadingIndicator } from './LoadingIndicator'

interface GetInputPropsOptions extends React.HTMLProps<HTMLInputElement> {}

export interface AutocompleteProps extends DefaultProps {
	inputRef?: React.RefObject<HTMLInputElement>
	inputProps?: Partial<Omit<GetInputPropsOptions, 'label'> & TextFieldProps>
	selected?: any
	emptyMessage?: string
	noItemPlaceholder?: string
	preventInitialQuery?: boolean
	clearable?: boolean
	segments?: ResultSegment[]
	segmentItemsLength?: number
	alwaysShowOnOpen?: boolean
	refreshListAfterChange?: boolean | string
	sortAlphabetically?: boolean
	query(search: string): Promise<any[]> | any[]
	initialQuery?(search: string): Promise<any[]> | any[]
	labelFn?(option: any): string
	/** Gets the ID of the option to find which one is selected */
	itemValueOf?(option: any): string | number
	onChange?(selected: any, e?: React.MouseEvent | React.KeyboardEvent): void
	renderOption?(option: any): React.ReactNode
	disabledCondition?(option: any): boolean
	filterOptions?: (options: any[]) => any[]
	hideLabel?: boolean
}

type InternalProps = AutocompleteProps & {
	isMobile?: boolean
	segmentLabels?: Record<string, string>
	translations: Record<'show-all' | 'show-less', string>
}

interface IState {
	options: unknown[]
	selected: unknown
	search: string
	showAll: ImmutableMap<string, boolean>
	open: boolean
	blurDisabled: boolean
	blockClose: boolean
	searchPromise: any | null
	resolvedSearch: string | null
}

export const Dropdown = styled.div.attrs(addE2EAttrs)<E2E>`
	position: absolute;
	margin-top: 5px;
	top: 100%;
	left: 0;
	right: 0;
	box-shadow: ${boxShadowFloat};
	background: white;
	z-index: 2000;
	max-height: 30vh;
	overflow: auto;
	border-radius: 5px;
	font-size: ${pixelFontSize(14)};

	${(p) => p.theme.breakpoints.down('md')} {
		margin-top: 20px;
		top: unset;
		height: calc(100% - 85px);
		position: static;
		max-height: unset;
		flex: 1 1 0;
		box-shadow: none;
	}
`

export const AutocompleteContainer = styled(RelativeContainer)`
	display: flex;

	${(p) => p.theme.breakpoints.down('md')} {
		flex: 1;

		${Dropdown} {
			box-shadow: none;
			max-height: none;
		}
	}
`

const ClearButton = styled.span`
	position: absolute;
	right: ${spacing / 2}px;
	top: 50%;
	cursor: pointer;
	font-size: 0.9em;
	transform: translateY(-50%);
`

const SegmentTitle = styled.div.attrs(addE2EAttrs)<E2E>`
	margin-right: auto;
`

const SegmentTitleContainer = styled.div.attrs(addE2EAttrs)<E2E>`
	${flex()}
	padding: 15px 20px;
	color: ${linkBlue};
	${font({ weight: 'bold' })}
	border-bottom: 1px solid ${linkBlue};
`
const ShowAllInSegment = styled.div.attrs(addE2EAttrs)<E2E>`
	${font({ size: 12 })}
	color: ${linkBlue};
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`

const TooltipContainer = styled.div`
	flex: 1 1;
	${(p) => p.theme.breakpoints.down('md')} {
		height: 100%;
		width: 100%;
		${flex({ direction: 'column' })}
	}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class Autocomplete extends React.Component<InternalProps, IState> {
	public static defaultProps: Partial<InternalProps> = {
		inputProps: {},
		labelFn(this: React.Component<InternalProps, IState>, option: any) {
			if (!option) {
				return this.props.noItemPlaceholder || 'No item'
			}
			return option.name || option.value || option.toString()
		},
		itemValueOf(this: React.Component<InternalProps, IState>, option: any) {
			if (!option && option !== 0 && option !== false) {
				return null
			}
			if (option.hasOwnProperty('valueOf') && typeof option.valueOf === 'function') {
				return option.valueOf()
			}
			if (option.hasOwnProperty('id')) {
				return option.id
			}
			if (option.hasOwnProperty('label')) {
				return option.label
			}
			if (option.hasOwnProperty('name')) {
				return option.name
			}
			return option
		},
		segmentItemsLength: 5,
	}

	public static getDerivedStateFromProps(props: InternalProps, state: IState) {
		if (props.itemValueOf!(props.selected) !== props.itemValueOf!(state.selected)) {
			return {
				selected: props.selected,
			}
		}

		return null
	}

	public state: IState = {
		options: [],
		selected: this.props.selected,
		search: this.props.selected ? this.props.labelFn!(this.props.selected) : '',
		showAll: new ImmutableMap(),
		open: false,
		blurDisabled: false,
		blockClose: false,
		searchPromise: null,
		resolvedSearch: null,
	}

	private wrapperRef: HTMLElement | null
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef()
	private _mounted = false

	public componentDidMount() {
		this._mounted = true
		if (!this.props.preventInitialQuery) {
			this.getInitialResults('')
		}
	}

	public componentWillUnmount() {
		Tooltip.hide()
		this._mounted = false
	}

	public componentDidUpdate(props: InternalProps) {
		if (props.selected !== this.props.selected) {
			this.setState({
				search: this.props.selected ? this.props.labelFn!(this.props.selected) : '',
				selected: this.props.selected,
			})
		}
	}

	private getInputRef() {
		return this.props.inputRef ?? this.inputRef
	}

	private getOptionValue(option: unknown) {
		return String(this.props.itemValueOf?.(option))
	}

	getFiteredOptions = () => {
		const { filterOptions } = this.props
		const { options } = this.state
		return filterOptions ? filterOptions(options) : options
	}

	public render() {
		const {
			className,
			inputProps = {},
			style,
			emptyMessage,
			clearable,
			segments,
			sortAlphabetically,
			hideLabel,
			isMobile,
		} = this.props
		const { search, open } = this.state
		let options = this.getFiteredOptions()
		const clearAction = () =>
			clearable ? (
				<ClearButton onClick={() => this.onSelect(null)}>
					<SvgIcon src={CrossIcon} />
				</ClearButton>
			) : (
				inputProps.trailing
			)
		const { className: inputClassName, ...otherInputProps } = inputProps
		const e2e = this.props.e2e || this.props['data-test']

		if (sortAlphabetically && !segments?.length) {
			options = sortBy(options, (o) => this.props.labelFn!(o).toLocaleLowerCase())
		}

		const renderDropdown = (
			<TooltipContainer>
				<Tooltip title={options && !options.length && emptyMessage ? emptyMessage : ''}>
					<TextField
						className={classNames(inputClassName, '--input')}
						{...(otherInputProps as any)}
						value={search}
						tabIndex={0}
						onChange={(e) => this.search(e.target.value)}
						onFocus={(e) => this.wrappedOnFocus(e)}
						onBlur={(e) => this.wrappedOnBlur(e)}
						onClick={(e) => this.wrappedOnClick(e)}
						trailing={clearAction()}
						e2e={`${e2e ? `${e2e}.` : ''}Autocomplete.Input`}
						hideLabel={hideLabel || (!open && isMobile)}
						inputRef={this.getInputRef()}
					/>
				</Tooltip>
				{open && !inputProps.disabled && (!this.props.preventInitialQuery || search || this.props.alwaysShowOnOpen) ? (
					<Dropdown
						onMouseEnter={() => this.setState({ blurDisabled: true })}
						onMouseLeave={() => this.setState({ blurDisabled: false })}
						e2e="Autocomplete.Dropdown"
					>
						{this.state.searchPromise ? (
							<LoadingIndicator />
						) : segments?.length ? (
							segments.map((seg) => this.renderSegment(seg))
						) : (
							(options || []).map((o, i) => this.renderResult(o, i))
						)}
					</Dropdown>
				) : null}
			</TooltipContainer>
		)

		const inputElement = (
			<AutocompleteContainer
				className={classNames(className, '--container')}
				style={style}
				ref={(ref) => {
					if (ref && ref !== this.wrapperRef) {
						this.wrapperRef = ref
					}
				}}
				onKeyDown={(e) => this.handleKeyDown(e)}
				e2e={`Autocomplete.${e2e}`}
			>
				{renderDropdown}
			</AutocompleteContainer>
		)
		return (
			<>
				{this.props.isMobile ? (
					<MobileDialogWithArrow
						open={open}
						onClose={() => {
							this.setState({ open: false }, handleIOSInput)
						}}
						onOpen={() => {
							this.getInputRef().current?.focus()
						}}
					>
						{inputElement}
					</MobileDialogWithArrow>
				) : (
					inputElement
				)}
			</>
		)
	}

	private renderSegment(segment: ResultSegment) {
		const { showAll } = this.state
		const { sortAlphabetically } = this.props
		const options = this.getFiteredOptions()
		const segmentResults = options.filter(segment.filterFn)

		let segmentItemsLength = segment.itemsLength ?? this.props.segmentItemsLength ?? 5

		if (segment.alwaysExpanded) {
			segmentItemsLength = segmentResults.length
		}

		const hasExtra = segmentResults.length > segmentItemsLength
		const showingAll = hasExtra && showAll.get(segment.key)
		let visibleOptions = showAll.get(segment.key) ? segmentResults : segmentResults.slice(0, segmentItemsLength)

		if (sortAlphabetically) {
			visibleOptions = sortBy(visibleOptions, (o) => this.props.labelFn!(o).toLocaleLowerCase())
		}
		const onClick = (e: React.MouseEvent<any>) => {
			e.stopPropagation()
			this.setState({ showAll: showAll.set(segment.key, !showAll.getWithFallback(segment.key, false)) })
		}

		const segmentLabel = this.props.segmentLabels?.[segment.label] ?? segment.label
		return segmentResults.length ? (
			<React.Fragment key={segment.key}>
				{segmentLabel && (
					<SegmentTitleContainer e2e={`Autocomplete.Segment`}>
						{segment.icon}
						<SegmentTitle e2e={`Autocomplete.Segment.Title`}>{segmentLabel}</SegmentTitle>
						{hasExtra ? (
							<ShowAllInSegment onClick={onClick} e2e="Autocomplete.ShowAll">
								{showingAll ? this.props.translations['show-less'] : this.props.translations['show-all']}
							</ShowAllInSegment>
						) : null}
					</SegmentTitleContainer>
				)}
				{visibleOptions.map((o, optIdx) => this.renderResult(o, optIdx))}
			</React.Fragment>
		) : null
	}

	private renderResult(option: any, optIdxInSeg: number) {
		const { open, selected } = this.state
		const { disabledCondition } = this.props
		const disabled = disabledCondition ? disabledCondition(option) : false
		const value = this.getOptionValue(option)
		return (
			<OptionItem
				component="div"
				key={optIdxInSeg}
				onClick={eventStopper(!disabled ? (e) => this.onSelect(option, e) : undefined)}
				data-value={value}
				data-test="Autocomplete.Option"
				tabIndex={open ? 0 : -1}
				selected={!!selected && value === this.getOptionValue(selected)}
				disabled={disabled}
			>
				{this.props.renderOption ? this.props.renderOption!(option) : this.props.labelFn!(option)}
			</OptionItem>
		)
	}

	private handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
		const keyPressed: Key = e.key as Key
		if (keyPressed === Key.Tab) {
			this.setState({ open: false, blockClose: false })
			return
		}

		if (![Key.Up, Key.Down, Key.Enter].includes(keyPressed)) {
			return
		}

		e.preventDefault()
		e.stopPropagation()

		const { currentTarget } = e
		const optionElements = Array.from(currentTarget.querySelectorAll<HTMLElement>('.--option'))
		let activeIdx = optionElements.indexOf(document.activeElement! as HTMLElement)
		if (activeIdx === -1) {
			activeIdx = optionElements.findIndex(
				(el) => (el.dataset.value ?? '') === this.getOptionValue(this.state.selected),
			)
		}

		// make sure dropdown remains open
		this.setState(
			{
				blockClose: true,
				open: [Key.Up, Key.Down].includes(keyPressed),
			},
			() => {
				// Enter
				if (keyPressed === Key.Enter) {
					const activeElement = optionElements[Math.max(activeIdx, 0)]
					if (activeElement) {
						const activeValue = activeElement.dataset.value ?? ''
						const activeOption = this.state.options.find((o) => this.getOptionValue(o) === activeValue)
						if (activeOption) {
							this.onSelect(activeOption, e)
						}
					}
					this.setState({ blockClose: false })
					return
				}

				let newIdx: number | null = null

				// Up/Down/Tab
				if (activeIdx === null) {
					// No selection yet - start at top/bottom
					newIdx = keyPressed === Key.Up ? optionElements.length - 1 : 0
				} else {
					// Has selection - continue or wrap around
					if (keyPressed === Key.Up) {
						newIdx = activeIdx > 0 ? activeIdx - 1 : optionElements.length - 1
					} else {
						// DownArrow
						newIdx = activeIdx < optionElements.length - 1 ? activeIdx + 1 : 0
					}
				}

				if (newIdx !== null && newIdx < optionElements.length && newIdx > -1) {
					optionElements[newIdx].focus()
				}

				this.setState({ blockClose: false })
			},
		)
	}

	private wrappedOnFocus(e: React.FocusEvent<any>) {
		const { inputProps = {} } = this.props
		e.persist()
		e.stopPropagation()
		if (!inputProps.disabled) {
			this.setState({ open: true, blurDisabled: false, blockClose: false }, () => {
				if (inputProps.onFocus) {
					inputProps.onFocus(e)
				}
			})

			// FIX: Reset search after `search` state changed
			const search =
				this.state.selected && typeof this.props.refreshListAfterChange === 'string'
					? this.props.refreshListAfterChange
					: this.state.search
			if (search !== this.state.resolvedSearch) {
				this.getResults(search)
			}
		}
	}

	private wrappedOnBlur(e: React.FocusEvent<any>) {
		const { inputProps = {} } = this.props
		e.persist()
		e.stopPropagation()
		if (!inputProps.disabled && !this.state.blurDisabled && !this.state.blockClose) {
			this.setState(
				{
					search: this.state.selected ? this.props.labelFn!(this.state.selected) : '',
				},
				() => {
					if (inputProps.onBlur) {
						inputProps.onBlur(e)
					}
				},
			)
		}
	}

	private wrappedOnClick(e: React.MouseEvent<any>) {
		const { inputProps = {} } = this.props
		e.persist()
		e.stopPropagation()
		if (!inputProps.disabled) {
			this.setState({ open: true }, () => {
				if (inputProps.onClick) {
					inputProps.onClick(e)
				}
			})
		}
	}

	public handleClickOutside() {
		if (this.props.isMobile) {
			return
		}
		this.setState({ open: false })
	}

	private getInitialResults(search: string) {
		this.getResults(search, this.props.initialQuery)
	}

	private async getResults(search: string, loadResults = this.props.query) {
		const promise = loadResults(search || '')
		this.setState({ searchPromise: promise })

		try {
			const results = await promise

			if (this._mounted && this.state.searchPromise === promise) {
				this.setState({ searchPromise: null, options: results, resolvedSearch: search || '' })
			}
		} catch (err) {
			if (this._mounted && this.state.searchPromise === promise) {
				this.setState({ searchPromise: null })
			}

			throw err
		}
	}

	private search(terms: string) {
		this.setState({ search: terms }, () => {
			this.getResults(terms)
		})
	}

	private onSelect(selected: any, e?: React.MouseEvent | React.KeyboardEvent) {
		const { onChange, disabledCondition } = this.props
		const { search } = this.state

		const target: HTMLInputElement | undefined = e ? ((e.currentTarget || e.target) as any) : undefined

		if (target) {
			this.focusOnInput(target)
		}

		if (disabledCondition?.(selected)) {
			return
		}

		const input = this.wrapperRef?.querySelector<HTMLInputElement>('.--input input')
		const newSearch = selected ? this.props.labelFn!(selected) : ''

		this.setState({ open: false, selected, search: newSearch, blurDisabled: false }, () => {
			if (onChange) {
				onChange(selected, e)
			}
			if (input) {
				triggerInputChangeEvent(input, newSearch)
			}
			if (this.props.refreshListAfterChange !== undefined) {
				this.getResults(
					typeof this.props.refreshListAfterChange === 'string' ? this.props.refreshListAfterChange : search,
				)
			}
		})
	}

	private focusOnInput(el: HTMLElement) {
		const wrapper = getClosestElement(el, { selector: '.--container' })
		wrapper?.querySelector<HTMLInputElement>('.--input')?.focus()
	}
}

const _AutoComplete = onClickOut(Autocomplete)

export default function AutoComplete(props: AutocompleteProps) {
	const { t } = useTranslation()
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
	return (
		<_AutoComplete
			{...props}
			isMobile={isMobile}
			// TODO: Move segment lables to corresponding component
			segmentLabels={{
				'locations.autocomplete-segments.addresses': t('locations.autocomplete-segments.addresses', 'Addresses'),
				'locations.autocomplete-segments.airports': t('locations.autocomplete-segments.airports', 'Airports'),
				'locations.autocomplete-segments.cities': t('locations.autocomplete-segments.cities', 'Cities'),
				'locations.autocomplete-segments.company-locations': t(
					'locations.autocomplete-segments.company-locations',
					'Company Locations',
				),
				'locations.autocomplete-segments.hotels': t('locations.autocomplete-segments.hotels', 'Hotels'),
			}}
			translations={{
				'show-all': t('autocomplete.show-all', 'Show All'),
				'show-less': t('autocomplete.show-less', 'Show Less'),
			}}
		/>
	)
}
