import { z } from 'zod'

import { useStoreValue } from 'src/lib/react-hooks/generic-hooks'

export const CompanyFeatureFlagsZ = z
	.object({
		enable_new_flight_search: z.boolean(),
		enable_new_flight_search_debug: z.boolean(),
		enable_non_traditional_accommodation: z.boolean(),
	})
	.partial()

export type CompanyFeatureFlags = z.infer<typeof CompanyFeatureFlagsZ>

export function useCompanyFeatureFlags() {
	return useStoreValue((s) => s.myCompany?.feature_flag ?? undefined)
}
