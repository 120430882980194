import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { formatDate } from 'src/pages/UsageDetails/getSortedUsageFeatures'
import CalendarIcon from 'src/refactor/assets/icons/calendar.svg'
import { ActiveUserFee, Fee } from 'src/travelsuit/fees.types'

import { FeeCardWithPayment } from './FeeCardWithPayment'
import { FeeDetail } from './FeeDetail'

interface ActiveUserFeeCardProps {
	fee: Fee<ActiveUserFee>
	onFeesReload: () => void
}

export function ActiveUserFeeCard({ fee, onFeesReload }: ActiveUserFeeCardProps) {
	const { t } = useTranslation()

	const { users_number, invoice_profile } = fee[fee.service_type]

	return (
		<FeeCardWithPayment
			fee={{ ...fee, active_user: { ...fee.active_user, created_dt: '', next_payment_date: '' } }}
			feeDetails={
				<FeeDetail
					tooltip={t('usage-details.start-date-renewal-date', 'Start Date - Renewal Date')}
					iconSrc={CalendarIcon}
					e2e="CreationDate"
					content={[fee.active_user.created_dt, fee.active_user.next_payment_date].map(formatDate).join(' - ')}
				/>
			}
			primaryInfo={t('number-of-users', '{{count}} users', { count: users_number })}
			invoiceProfile={invoice_profile}
			paymentModalTitle={t('usage-details.pay-again-modal.active-user', 'Active User Fee')}
			onFeesReload={onFeesReload}
			displayInvoiceProfileNameForSubscription
		/>
	)
}
