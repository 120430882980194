import React from 'react'

import Autocomplete from 'src/atoms/Autocomplete/Autocomplete'
import { CreditCardOption } from 'src/atoms/CreditCardOption'
import { SvgIcon } from 'src/atoms/SvgIcon/SvgIcon'
import { getCreditCardNumbersWithName, PaymentMethodOptionAttributes } from 'src/lib/credit-card-utils'
import useTranslation from 'src/lib/i18n/UseTranslation'
import Card from 'src/refactor/assets/icons/card.svg'
import Reports from 'src/refactor/assets/icons/reports.svg'
import Man from 'src/refactor/assets/people/man.svg'
import { ResultSegment } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users.utils'
import { CreditCardType } from 'src/types/creditCard'

type PaymentMethod = {
	id: number
	card_name: string
	card_number: string
	card_type: CreditCardType
	invoice_profile?: {
		id: number
		display_name: string
	} | null
	user?: {
		id: number
		first_name: string
		last_name: string
	} | null
} & PaymentMethodOptionAttributes

function compareCreditCards(a: PaymentMethod, b: PaymentMethod) {
	if (a.disabled && !b.disabled) {
		return 1
	}
	if (!a.disabled && b.disabled) {
		return -1
	}
	return (
		getCreditCardNumbersWithName({ cardName: a.card_name, cardNumbers: a.card_number })?.localeCompare(
			getCreditCardNumbersWithName({ cardName: b.card_name, cardNumbers: b.card_number }),
		) || 0
	)
}

type Props<T extends PaymentMethod> = {
	paymentMethods: T[]
	selectedMethod: T | undefined | null
	onChange: (value: T) => void
	disabled?: boolean
	className?: string
	error?: boolean
	helperText?: string
	label?: string
}

export function PaymentMethodsAutocomplete<T extends PaymentMethod>({
	paymentMethods,
	selectedMethod,
	onChange,
	disabled,
	className,
	error,
	helperText,
	label,
}: Props<T>) {
	const { t } = useTranslation()

	const paymentSegments: ResultSegment[] = paymentMethods.reduce(
		(acc, method, index, arr) => {
			if (method.invoice_profile?.id && !acc.invoice[method.invoice_profile.id]) {
				acc.invoice[method.invoice_profile.id] = {
					icon: <SvgIcon src={Reports} />,
					key: 'invoice-' + method.invoice_profile.id,
					label: method.invoice_profile.display_name,
					filterFn: ({ invoice_profile }: PaymentMethod) => invoice_profile?.id === method.invoice_profile?.id,
					alwaysExpanded: true,
				}
			}

			if (method.user?.id && !acc.traveler[method.user.id]) {
				acc.traveler[method.user.id] = {
					icon: <SvgIcon src={Man} />,
					key: 'traveler-' + method.user.id,
					label: fullName(method.user),
					filterFn: ({ user }: PaymentMethod) => user?.id === method.user?.id,
					alwaysExpanded: true,
				}
			}

			if (index === arr.length - 1) {
				acc.segments = [
					{
						icon: <SvgIcon src={Card} />,
						key: 'temporary',
						label: t('trip-payment-method.temporary', 'Cards for this booking only (not saved)'),
						filterFn: ({ invoice_profile, user }: PaymentMethod) => {
							return !invoice_profile?.id && !user?.id
						},
						alwaysExpanded: true,
					},
					...Object.values(acc.invoice).sort((a, b) => a.label.localeCompare(b.label)),
					...Object.values(acc.traveler).sort((a, b) => a.label.localeCompare(b.label)),
				]
			}

			return acc
		},
		{ invoice: {}, traveler: {}, segments: [] } as {
			invoice: Record<string, ResultSegment>
			traveler: Record<string, ResultSegment>
			segments: ResultSegment[]
		},
	).segments

	return (
		<Autocomplete
			key={paymentMethods.length}
			selected={selectedMethod}
			className={className}
			inputProps={{
				error,
				helperText,
				disabled,
				fullWidth: true,
				label: label ?? t('trip-payment-method.render-value', 'Select payment method'),
				required: true,
			}}
			segments={paymentSegments}
			alwaysShowOnOpen
			disabledCondition={(method) => method.disabled}
			query={(terms) =>
				paymentMethods
					.filter((method) => {
						if (!terms) {
							return true
						}
						const { card_name, card_number, card_type, user, invoice_profile } = method
						return [
							card_name,
							card_number,
							card_type,
							fullName(user),
							invoice_profile?.display_name,
							getCreditCardNumbersWithName({ cardName: card_name, cardNumbers: card_number }),
						]
							.map((str) => str?.toLowerCase())
							.some((str) => str?.includes(terms.toLowerCase()))
					})
					.sort(compareCreditCards)
			}
			itemValueOf={(method: PaymentMethod) => (method ? method.id : 0)}
			labelFn={(method: PaymentMethod) => {
				return method
					? getCreditCardNumbersWithName({ cardName: method.card_name, cardNumbers: method.card_number })
					: ''
			}}
			renderOption={(method: PaymentMethod) => {
				return (
					<CreditCardOption
						disabled={method.disabled}
						cardBrand={method.card_type}
						cardName={method.card_name}
						cardNumbers={method.card_number}
						tooltipContent={method.tooltipContent}
					/>
				)
			}}
			onChange={onChange}
			e2e="TripPaymentMethod"
		/>
	)
}
