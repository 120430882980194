import { applyMiddleware, combineReducers, compose, createStore, Middleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'

import { ENV } from 'src/lib/env'
import { IAction } from 'src/redux/actions/action-helpers'
import { RootTypes } from 'src/redux/actions/actions.types'
import adminLocations, { AdminLocationsState } from 'src/redux/reducers/admin-locations.reducer'
import adminUsers, { AdminUsersState } from 'src/redux/reducers/admin-users.reducer'
import airlines, { AirlinesState } from 'src/redux/reducers/airlines.reducer'
import airports, { AirportsState } from 'src/redux/reducers/airports.reducer'
import alliances, { AlliancesState } from 'src/redux/reducers/alliances.reducer'
import auth, { AuthState } from 'src/redux/reducers/auth.reducer'
import carRates, { CarRatesState } from 'src/redux/reducers/car-rates.reducer'
import { companyCarProgramsReducer, CompanyCarProgramsState } from 'src/redux/reducers/company-car-programs.reducer'
import {
	companyFlightProgramsReducer,
	CompanyFlightProgramsState,
} from 'src/redux/reducers/company-flight-programs.reducer'
import {
	companyHotelProgramsReducer,
	CompanyHotelProgramsState,
} from 'src/redux/reducers/company-hotel-programs.reducer'
import {
	companyInvoiceProfilesReducer,
	CompanyInvoiceProfilesState,
} from 'src/redux/reducers/company-invoice-profiles.reducer'
import companyTypeModal, { CompanyTypeModalState } from 'src/redux/reducers/company-type-modal.reducer'
import countries, { CountriesState } from 'src/redux/reducers/countries.reducer'
import countryLanguages, { ICountryLanguagesState } from 'src/redux/reducers/country-languages.reducer'
import countryPOSReducer, { countryPosState } from 'src/redux/reducers/country-pos.reducer'
import covidInfo, { ICovidInfoState } from 'src/redux/reducers/covid-info.reducer'
import {
	customerSuccessConsultantReducer,
	CustomerSuccessConsultantState,
} from 'src/redux/reducers/customerSuccessConsultant.reducer'
import departments, { DepartmentsState } from 'src/redux/reducers/departments.reducer'
import {
	essentialTravelInformationReducer,
	EssentialTravelInformationState,
} from 'src/redux/reducers/essential-travel-information.reducer'
import expensePolicyReducer, { ExpensePolicyState } from 'src/redux/reducers/expense-policy.reducer'
import expenseReportsReducer, { ExpenseReportsState } from 'src/redux/reducers/expense-reports.reducer'
import expensesNewReducer, { ExpensesNewState } from 'src/redux/reducers/expenses-new.reducer'
import fareRules, { FareRulesState } from 'src/redux/reducers/fare-rules.reducer'
import homepage, { HomePageState } from 'src/redux/reducers/homepage.reducer'
import hotelsInfo, { HotelsInfoState } from 'src/redux/reducers/hotels-info.reducer'
import hotelsRates, { HotelsRatesState } from 'src/redux/reducers/hotels-rates.reducer'
import { industriesReducer, IndustriesState } from 'src/redux/reducers/industries.reducer'
import itinerary, { ItineraryState } from 'src/redux/reducers/itinerary.reducer'
import liveUserLocations, { LiveUserLocationsState } from 'src/redux/reducers/live-user-locations.reducer'
import loading, { LoadingState } from 'src/redux/reducers/loading.reducer'
import myCompany, { MyCompanyState } from 'src/redux/reducers/my-company.reducer'
import myUserRoles, { MyUserRolesState } from 'src/redux/reducers/my-user-roles.reducer'
import {
	packagesAndBillingSettingsReducer,
	PackagesAndBillingSettingsState,
} from 'src/redux/reducers/packages-and-billing-settings.reducer'
import packagesUsages, { PackagesUsagesState } from 'src/redux/reducers/packages-usage.reducer'
import packages, { PackagesState } from 'src/redux/reducers/packages.reducer'
import recentSearches, { RecentSearchesState } from 'src/redux/reducers/recent-searches.reducer'
import reports, { ReportsState } from 'src/redux/reducers/reports.reducer'
import roles, { RolesState } from 'src/redux/reducers/roles.reducer'
import searchResultsNew, { SearchResultsNewState } from 'src/redux/reducers/search-results-new.reducer'
import searchResults, { SearchResultsState } from 'src/redux/reducers/search-results.reducer'
import searches, { SearchesState } from 'src/redux/reducers/searches.reducer'
import seatmaps, { SeatmapState } from 'src/redux/reducers/seatmap.reducer'
import {
	selectedDocumentsForTripsReducer,
	SelectedDocumentsForTripsState,
} from 'src/redux/reducers/selected-documents-for-trips.reducer'
import support, { SupportState } from 'src/redux/reducers/support.reducer'
import railRedirect, { RailRedirectState } from 'src/redux/reducers/train-redirect.reducer'
import trainTicketDiscounts, { TrainDiscountTicketsState } from 'src/redux/reducers/train-tickets.reducer'
import travelPolicy, { TravelPolicyState } from 'src/redux/reducers/travel-policy.reducer'
import travelers, { TravelersState } from 'src/redux/reducers/travelers.reducer'
import tripApprovals, { TripApprovalsState } from 'src/redux/reducers/trip-approvals.reducer'
import tripCancellationStatus, {
	TripCancellationStatusState,
} from 'src/redux/reducers/trip-cancellation-status.reducer'
import tripsGaps, { TripGapsState } from 'src/redux/reducers/trip-gaps.reducer'
import { TripOrders, tripOrdersReducer } from 'src/redux/reducers/trip-orders.reducer'
import tripsPaymentMethods, { TripPaymentMethodsState } from 'src/redux/reducers/trip-payment-methods.reducer'
import { tripPurposesReducer, TripPurposesState } from 'src/redux/reducers/trip-purposes-reducer'
import tripRequests, { TripRequestsState } from 'src/redux/reducers/trip-requests.reducer'
import trips, { TripsState } from 'src/redux/reducers/trips.reducer'
import userPermissions, { UserPermissionsState } from 'src/redux/reducers/user-permissions.reducer'
import userPOS, { userPosState } from 'src/redux/reducers/user-pos.reducer'
import analyticsMiddleware from 'src/redux/stores/analytics-middleware'
//Middleware imports should be last to avoid import shenanigans in tests
//TODO: Deal with order of imports
import requestMiddleware from 'src/redux/stores/request-middleware'

export interface ApplicationState {
	adminLocations: AdminLocationsState
	adminUsers: AdminUsersState
	airlines: AirlinesState
	airports: AirportsState
	alliances: AlliancesState
	auth: AuthState
	carRates: CarRatesState
	companyCarPrograms: CompanyCarProgramsState
	companyFlightPrograms: CompanyFlightProgramsState
	companyHotelPrograms: CompanyHotelProgramsState
	companyInvoiceProfiles: CompanyInvoiceProfilesState
	companyTypeModal: CompanyTypeModalState
	countries: CountriesState
	countryLanguages: ICountryLanguagesState
	covidInfo: ICovidInfoState
	customerSuccessConsultant: CustomerSuccessConsultantState
	departments: DepartmentsState
	essentialTravelInformation: EssentialTravelInformationState
	expensePolicy: ExpensePolicyState
	expenseReports: ExpenseReportsState
	expensesNew: ExpensesNewState
	fareRules: FareRulesState
	homepage: HomePageState
	hotelsInfo: HotelsInfoState
	hotelsRates: HotelsRatesState
	industries: IndustriesState
	itinerary: ItineraryState
	liveUserLocations: LiveUserLocationsState
	loading: LoadingState
	myCompany: MyCompanyState
	myUserRoles: MyUserRolesState
	packages: PackagesState
	packagesAndBillingSettings: PackagesAndBillingSettingsState
	packagesUsages: PackagesUsagesState
	posList: countryPosState
	railRedirect: RailRedirectState
	recentSearches: RecentSearchesState
	reports: ReportsState
	roles: RolesState
	searches: SearchesState
	searchResults: SearchResultsState
	searchResultsNew: SearchResultsNewState
	seatmaps: SeatmapState
	selectedDocumentsForTrips: SelectedDocumentsForTripsState
	support: SupportState
	trainTicketDiscounts: TrainDiscountTicketsState
	travelers: TravelersState
	travelPolicy: TravelPolicyState
	tripApprovals: TripApprovalsState
	tripCancellationStatus: TripCancellationStatusState
	tripOrders: TripOrders
	tripPurposes: TripPurposesState
	tripRequests: TripRequestsState
	trips: TripsState
	tripsGaps: TripGapsState
	tripsPaymentMethods: TripPaymentMethodsState
	userPermissions: UserPermissionsState
	userPOS: userPosState
}

const isProduction = ENV.IS_PROD

const middlewares: Middleware[] = []

// eslint-disable-next-line no-process-env
if (!isProduction && process.env.REACT_APP_DISABLE_REDUX_LOGS !== 'true') {
	const logger = createLogger({ collapsed: true })

	middlewares.push(logger)
}

middlewares.push(requestMiddleware, analyticsMiddleware)

const composedMiddlewares = isProduction
	? compose(applyMiddleware(...middlewares))
	: composeWithDevTools(applyMiddleware(...middlewares))

const appReducer = combineReducers<ApplicationState, IAction>({
	auth,
	trips,
	travelers,
	adminUsers,
	adminLocations,
	airlines,
	airports,
	itinerary,
	userPermissions,
	roles,
	travelPolicy,
	searchResults,
	searchResultsNew,
	loading,
	myUserRoles,
	myCompany,
	alliances,
	hotelsRates,
	hotelsInfo,
	reports,
	countries,
	support,
	departments,
	searches,
	tripsGaps,
	recentSearches,
	seatmaps,
	fareRules,
	tripsPaymentMethods,
	packages,
	packagesUsages,
	liveUserLocations,
	homepage,
	tripRequests,
	tripApprovals,
	carRates,
	companyTypeModal,
	trainTicketDiscounts,
	railRedirect,
	tripCancellationStatus,
	userPOS,
	posList: countryPOSReducer,
	covidInfo,
	countryLanguages,
	industries: industriesReducer,
	companyInvoiceProfiles: companyInvoiceProfilesReducer,
	packagesAndBillingSettings: packagesAndBillingSettingsReducer,
	tripPurposes: tripPurposesReducer,
	essentialTravelInformation: essentialTravelInformationReducer,
	selectedDocumentsForTrips: selectedDocumentsForTripsReducer,
	customerSuccessConsultant: customerSuccessConsultantReducer,
	companyFlightPrograms: companyFlightProgramsReducer,
	companyHotelPrograms: companyHotelProgramsReducer,
	companyCarPrograms: companyCarProgramsReducer,
	expensesNew: expensesNewReducer,
	expensePolicy: expensePolicyReducer,
	expenseReports: expenseReportsReducer,
	tripOrders: tripOrdersReducer,
})

function rootReducer(state: ApplicationState, action: IAction): ApplicationState {
	if (action.type === RootTypes.ClearStore) {
		state = action.payload
	}

	return appReducer(state, action)
}

let store: Store<ApplicationState>

export function createApplicationStore(initialState: ApplicationState) {
	store = createStore(rootReducer, initialState, composedMiddlewares)
	return store
}

export function getStore() {
	return store
}

export function getStoreState(): ApplicationState {
	return getStore().getState()
}
