import React from 'react'

import { secondaryBlack } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

import TextField, { IProps as TextFieldProps } from '../TextField/TextField'

interface IProps extends TextFieldProps {
	currency?: string
}

const CurrencyText = styled.span`
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	color: ${secondaryBlack};
	${font({ size: 14 })}
`

const CurrencyTextField: React.FunctionComponent<IProps> = (props) => {
	const { className, trailing, currency } = props

	return (
		<TextField
			className={className}
			trailing={
				<React.Fragment>
					{trailing}
					<CurrencyText>{currency}</CurrencyText>
				</React.Fragment>
			}
			{...props}
			hideStepperArrows
		/>
	)
}

CurrencyTextField.defaultProps = {
	type: 'number',
	currency: '$USD',
}

export default CurrencyTextField
