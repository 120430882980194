export const brightRed = '#F12F47'
export const darkBlue = '#0A0447'
export const brightTurquoise = '#02BBAA'
export const yellow = '#FCA602'
export const yellowPaleSemiTransparent = 'rgba(255, 246, 201, 0.8)'
export const darkRed = '#D83146'
export const purple = '#584EC0'
export const darkTurquoise = '#068D9D'
export const successGreen = '#42B883'
export const white = '#FFF'
export const borderGray = '#C4C4C4'
export const backgroundGray = '#F0F1F6'
export const backgroundGrayLight = '#F7F8FA'
export const disabledGray = '#DADADA'
export const linkBlue = '#1783C8'
export const hoverBlue = '#E9F6FF'
export const mainBlack = '#2E2E2E'
export const secondaryBlack = '#888888'
export const fontBlack = mainBlack
export const fontSecondary = secondaryBlack
export const brightTurquoiseLight = '#90DFD7'
export const expandedContentGray = '#D8DAE3'
export const transparent = 'transparent'
export const trustYou = brightTurquoise
export const gray400 = '#909499'
export const blue700 = '#0F5E90'
export const graySemiTransparent = `rgba(227, 228, 233, 0.4)`
export const steelGray = '#E3E4E9'
export const paleGray = '#AAAAAA'
