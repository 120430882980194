import React from 'react'

import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import Back from 'src/refactor/assets/actions/back.svg'
import TrashIcon from 'src/refactor/assets/actions/delete.svg'
import Edit from 'src/refactor/assets/actions/edit.svg'
import ChevronDownIcon from 'src/refactor/assets/actions/open.svg'
import CrossRoundIcon from 'src/refactor/assets/icons/cross-round.svg'
import CrossIcon from 'src/refactor/assets/icons/cross.svg'
import { linkBlue } from 'src/refactor/colors'
import styled, { css, padPx } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

export type IconSize = 8 | 10 | 12 | 16 | 18 | 24 | 40 | 60

export interface IProps extends DefaultElProps<'span'> {
	size?: IconSize
	src?: string
	height?: number
	middle?: boolean
	noStroke?: boolean
}

export const baseStyles = css<{ size?: number; height?: number; middle?: boolean; noStroke?: boolean }>`
	fill: currentColor;
	stroke: ${(props) => (props.noStroke ? '' : 'currentColor')};
	display: inline-block;
	width: ${(props) => (props.size ? padPx(props.size) : '1em')};
	height: ${(props) => (props.height ? padPx(props.height) : props.size ? padPx(props.size) : 'auto')};
	${(props) => (props.middle ? `vertical-align: middle;` : '')}
	flex-shrink: 0;
`

export const IconBase = styled.span`
	${baseStyles}
`

export const InlineSvgIcon = React.forwardRef<HTMLSpanElement, IProps>(
	({ src = '', size = 16, ...rest }: IProps, ref) => {
		return <IconBase dangerouslySetInnerHTML={{ __html: src }} ref={ref} size={size} {...rest} />
	},
)

InlineSvgIcon.defaultProps = {
	size: 16,
}

export const Icon = styled(IconBase)<{ iconSrc: string; color?: string }>`
	background-color: ${(props) => props.color || 'currentColor'};
	mask-image: url(${(props) => props.iconSrc});
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center;
`

const _SvgIcon = React.forwardRef<HTMLSpanElement, IProps>(({ src = '', ...rest }: IProps, ref) => {
	return <Icon ref={ref} {...rest} iconSrc={src} />
})

_SvgIcon.defaultProps = {
	size: 16,
}

export const SvgIcon = styled(_SvgIcon).attrs(addE2EAttrs)<E2E>``
SvgIcon.defaultProps = _SvgIcon.defaultProps as any

export const ButtonIcon = styled(SvgIcon)`
	vertical-align: middle;
	margin-right: 6px;
`

const ChevronDown = styled(SvgIcon).attrs(() => ({ src: ChevronDownIcon }))`
	vertical-align: middle;
`

export const GoBack = styled(SvgIcon).attrs(() => ({ src: Back, size: 24 }))``

export const ChevronRight = styled(ChevronDown)`
	transform: rotate(-90deg);
`

export const expandIconTransitionCss = css`
	transition: all 150ms ease-in-out;
`

export const ExpandIcon = styled(ChevronDown).attrs(addE2EAttrs)<E2E & { expanded?: boolean }>`
	${expandIconTransitionCss}
	line-height: 1;
	${(props) => (props.expanded ? `transform: rotate(180deg);` : '')}
`

export const CloseIcon = styled(SvgIcon).attrs(() => ({ src: CrossIcon }))``
export const RoundCloseIcon = styled(SvgIcon).attrs(() => ({ src: CrossRoundIcon }))`
	${expandIconTransitionCss}

	${(props) =>
		props.onClick
			? `
		cursor: pointer;
		&:hover {
			color: ${linkBlue};
		}
	`
			: ''}
`

export const EditIcon = styled(SvgIcon).attrs(() => ({ src: Edit }))``

export const DeleteIcon = styled(SvgIcon).attrs(() => ({ src: TrashIcon }))``

export default SvgIcon
