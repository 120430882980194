import React from 'react'
import { useTranslation } from 'react-i18next'

import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { backgroundGray, brightRed, mainBlack, white } from 'src/refactor/colors'
import styled from 'src/styles'
import { WordlineOrderStatus } from 'src/travelsuit/wordline'

const StatusBadge = styled.div.attrs(() => ({
	'data-test': 'StatusBadge',
}))<{ bgColor: string; textColor: string }>`
	padding: 3px 8px;
	background-color: ${(props) => props.bgColor};
	color: ${(props) => props.textColor};
	border-radius: 30px;
	font-size: 10px;
	line-height: 14px;
	font-weight: bold;
`

export interface FeeStatusBadgeProps {
	status: WordlineOrderStatus | null
}

export function FeeStatusBadge({ status }: FeeStatusBadgeProps) {
	const { t } = useTranslation()

	if (status === WordlineOrderStatus.Refunded) {
		return (
			<StatusBadge bgColor={brightRed} textColor={white}>
				{t('usage-details.fees.status.refunded', 'Refunded')}
			</StatusBadge>
		)
	}

	if (status === WordlineOrderStatus.RefundPending) {
		return (
			<StatusBadge bgColor={brightRed} textColor={white}>
				{t('usage-details.fees.status.refund-pending', 'Refund pending')}
			</StatusBadge>
		)
	}

	if (status === WordlineOrderStatus.CaptureFailed || status === WordlineOrderStatus.Redirected) {
		return (
			<StatusBadge bgColor={brightRed} textColor={white}>
				{t('usage-details.fees.status.failed', 'Payment failed')}
			</StatusBadge>
		)
	}

	if (status === WordlineOrderStatus.CapturePending || status === WordlineOrderStatus.Validating) {
		return (
			<Tooltip
				title={t(
					'itinerary-breakdown.trip-fee-processing',
					'We are still trying to finalize your trip. Processing time may vary depending on the bank. If your payment fails, we will notify you.',
				)}
			>
				<StatusBadge bgColor={backgroundGray} textColor={mainBlack}>
					{t('usage-details.fees.status.processing', 'Charge in progress')}
				</StatusBadge>
			</Tooltip>
		)
	}

	return null
}
